import React from 'react'
import styled from 'styled-components'

const getProgressWidth = (counter, max, width) => {
  let progressWidth = max !== 0 ? counter * (parseInt(width) / max) : 0
  console.log('check check', progressWidth)
  return `width:${progressWidth}px;`
}

const WrapperStyle = styled.div`
  ${({ width }) => width && `width:${width}px;`}
  height: 40px;
  line-height: 40px;
  background: #f2f2f2;
  position: relative;
  margin-top: 1rem;
  padding: 0;
  border-radius: 5px;
  &:after {
    content: '';
    ${({ counter, max, width }) => width && counter && max && getProgressWidth(counter, max, width)}
    height: 4px;
    background: rgb(24, 153, 215);
    position: absolute;
    bottom: -4px;
    left: 0;
  }
`

const OverlayStyle = styled.div`
  background: #e6e6e6;
  ${({ counter, max, width }) =>
    width && counter !== 0 ? getProgressWidth(counter, max, width) : 'width:0px;'}
  height: 100%;
  border-radius: 5px;
`

const TextStyle = styled.div`
  margin-left: 20px;
`
const getDots = (counter) => (counter % 3 === 1 ? <span>.</span> : <span>..</span>)

const ProgressBar = ({ counter, max }) => (
  <WrapperStyle width="450" counter={counter} max={max}>
    <OverlayStyle width="450" counter={counter} max={max}>
      <TextStyle>
        Uploading
        {counter % 3 !== 0 && getDots(counter)}
      </TextStyle>
    </OverlayStyle>
  </WrapperStyle>
)

export default ProgressBar
