import React, { Component } from 'react'
import { connect } from 'react-redux'
import planActions from '../../actions/PlanActions'
import { toast } from 'react-toastify'

export class FileDownloadRender extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadLog = () => {
    let id = this.props && this.props.data && this.props.data.id
    this.props
      .getDownloadfailedImageData({
        projectId: id
      })
      .then((resp) => {
        if (resp.data.url) {
          let a2 = document.createElement('A')
          a2.href = resp.data.url
          document.body.appendChild(a2)
          a2.click()
          document.body.removeChild(a2)
        } else {
          toast.success(resp.data)
        }
      })
  }

  render() {
    return (
      <div
        onClick={this.downloadLog}
        style={{
          cursor: 'pointer',
          marginTop: '1rem',
          color: '#fff',
          backgroundColor: '#3f51b5',
          width: '5rem',
          textAlign: 'center',
          borderRadius: '4px'
        }}>
        Download
      </div>
    )
  }
}

const mapActionsToProps = {
  getDownloadfailedImageData: planActions.getDownloadfailedImageData
}

export default connect(null, mapActionsToProps)(FileDownloadRender)
