const mainReducerDefaultState = {
  overlayLoaderState: false,
  loaderText: null,
  activeNavIndex: 0,
  isGridView: '',
}

export default (state = mainReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOADER_STATE':
      return {
        ...state,
        overlayLoaderState: action.payload.status,
        loaderText: action.payload.text
      }
    case 'SET_ACTIVE_NAV_INDEX':
      return {
        ...state,
        activeNavIndex: action.payload
      }
      case 'SET_IS_GRID_VIEW':
      return {
        ...state,
        isGridView: action.payload
      }

    default:
      return state
  }
}
