import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import commonActions from '../actions'
import Sidebar from '../components/Sidebar'
import planActions from '../actions/PlanActions'
import Topbar from '../components/Topbar'
import dashboardRoutes from '../routes/dashboard'

const ContentStyle = styled.div`
  padding: 20px;
  margin: 60px 0 0 60px;
`
const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />
      return <Route exact path={prop.path} component={prop.component} key={key} />
    })}
  </Switch>
)

class AppLayout extends React.Component {
  state = {
    sidebaropen: false
  }
  componentDidMount() {
    let navIndex = dashboardRoutes
      .map((item, index) => (item.path === this.props.location.pathname ? index : -1))
      .filter((i) => i !== -1)[0]
    this.props.setActiveNavIndex(navIndex)
    console.log('loc', navIndex)
    this.props.getPlanList({
      page: 1,
      pageCount: 10000
    })
  }
  resizeSidebar = (e) => {
    e.preventDefault()
    this.setState({ sidebaropen: !this.state.sidebaropen })
  }

  render() {
    return (
      <>
        <Topbar title="Cataloging Tool" username="Philip" />
        <Sidebar />
        <ContentStyle>{switchRoutes}</ContentStyle>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    activeNavIndex: store.mainReducer.activeNavIndex
  }
}

const mapActionsToProps = {
  getPlanList: planActions.getPlanList,
  setActiveNavIndex: commonActions.setActiveNavIndex
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AppLayout))
