import io from 'socket.io-client'

const socket = io.connect(document.location.protocol+'//'+document.location.host);

const configureSocket = (dispatch) => {
  socket.on('connect', () => {
    console.log('connected')
  })
  socket.on('ATTRIBUTE_ALGO_COMPLETED', (result) => {
    console.log('Iam getting hit after some time', result)
    dispatch({ type: 'UPDATE_PLAN', payload: result })
  })
  return socket
}

export const runAttributeAlgo = (data) => socket.emit('RUN_ATTRIBUTE_ALGO', data)

export default configureSocket
