import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import commonActions from '../../actions'
import filterActions from '../../actions/filterActions'
import React, { Component } from 'react'
import AttributeView from './AttributeView'
import ImageSelector from './ImageView'
import ImageGrid from './ImageGrid'

class TabContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabName: 'Grid View'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filterData.attributeType !== this.props.filterData.attributeType) {
      if (this.props.filterData.attributeType === '') {
        this.setState({
          tabName: 'Grid View'
        })
      }
    }
    if (prevState.tabName !== this.state.tabName) {
      this.props.setIsGridView(this.state.tabName)
      if (this.state.tabName === 'Attribute View') {
        this.props.setStatusValue({ label: '', value: '' })
        this.props.setConfidenceValue({ label: '', value: '' })
        if(this.props.category && this.props.category.value){
          let ref = this
          setTimeout(function() {
            ref.props.setImagePage(prevProps.pageNumber)
          }, 0)
        }
      }
      if (this.state.tabName === 'Grid View') {
        this.props.setStatusValue({ label: 'Non Finalized', value: 'Non Finalized' })
        this.props.setStatusValueGridView()
        let ref = this
        setTimeout(function() {
          ref.props.setImagePage(prevProps.pageNumber)
        }, 0)
      }
    }
  }

  onTabChange = (val) => {
    this.setState({
      tabName: val.target.innerHTML
    })
  }

  render() {
    return (
      <>
        <div style={{ position: 'relative' }}>
          <div className="toggle-container">
            <ToggleButtonGroup
              size="sm"
              exclusive
              onChange={this.onTabChange}
              aria-label="text alignment">
              <ToggleButton
                size="sm"
                selected={
                  this.state.tabName === 'Grid View' &&
                  this.props.filterData && this.props.filterData.attributeType
                    ? true
                    : false
                }
                disabled={!(this.props.filterData && this.props.filterData.attributeType)}
                key={1}
                value="center"
                aria-label="centered">
                Grid View
              </ToggleButton>
              <ToggleButton
                size="sm"
                selected={this.state.tabName === 'Attribute View' ? true : false}
                key={2}
                value="center"
                aria-label="centered">
                Attribute View
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {this.state.tabName !== 'Attribute View' ? (
            <>
              <ImageGrid
                setImagePage={this.props.setImagePage}
                planid={this.props.planid}
                pageNumber={this.props.pageNumber}
                imagelist={this.props.imagelist}
                imageCount={this.props.imageCount}
              />
              <ImageSelector planid={this.props.planid} pageNumber={this.props.pageNumber} />
            </>
          ) : (
            <AttributeView
              planid={this.props.planid}
              setImagePage={this.props.setImagePage}
              pageNumber={this.props.pageNumber}
              imagelist={this.props.imagelist}
              imageCount={this.props.imageCount}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    category: store.filtersReducer.category,
  }
}

const mapActionsToProps = {
  setIsGridView: commonActions.setIsGridView,
  setAttributeType: filterActions.setAttributeType,
  setAttributeValue: filterActions.setAttributeValue,
  setStatusValue: filterActions.setStatusValue,
  setConfidenceValue: filterActions.setConfidenceValue,
  setStatusValueGridView: filterActions.setStatusValueGridView
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TabContainer))
