import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import indexRoutes from './routes'
import './App.css'

const App = ({ loaderState, loaderText }) => (
  <LoadingOverlay active={loaderState} spinner text={loaderText}>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />
      })}
    </Switch>
  </LoadingOverlay>
)

const mapStateToProps = (store) => {
  return {
    loaderState: store.mainReducer.overlayLoaderState,
    loaderText: store.mainReducer.loaderText
  }
}

export default connect(mapStateToProps, null)(App)
