import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './index.css'
import store from './store'
import App from './App'

// import { LicenseManager } from 'ag-grid-enterprise'
// import 'ag-grid-enterprise'

import configureSocket from './socket.js'

// LicenseManager.setLicenseKey(
//   'Evaluation_License-_Not_For_Production_Valid_Until_12_October_2019__MTU3MDgzNDgwMDAwMA==1581fb9d18de5a15031cbc2d036db827'
// )

// setup socket connection
export const socket = configureSocket(store.dispatch)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route component={App} />
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
