const commonActions = {
  setActiveNavIndex: (index) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_ACTIVE_NAV_INDEX',
        payload: index
      })
    }
  },
  setIsGridView: (name) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_IS_GRID_VIEW',
        payload: name
      })
    }
  }
}

export default commonActions
