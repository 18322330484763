const defaultColDef = {}
export default function(attributeTypeList) {
  const columnDefs = [
    {
      headerName: 'Image',
      field: 'url',
      cellRenderer: 'ImageCellRender',
      minWidth: 200
    },
    {
      headerName: 'Corp Attributes',
      field: 'rms',
      cellStyle: function(params) {
        return { overflow: 'hidden', whiteSpace: 'normal' }
      },
      minWidth: 300
    },
    {
      headerName: 'Category',
      cellRenderer: 'CategoryValueCellRenderer',
      minWidth: 300,
      cellStyle: function(params) {
        return { marginTop: '1.65rem' }
      }
    }
  ]
  attributeTypeList.forEach((attrType) => {
    const cellProperties = {
      cellRenderer: 'AttributeValueCellRenderer'
    }
    const dynamicProperties = {
      headerName: attrType.label,
      field: attrType.value,
      minWidth: 150,
      cellStyle: function(params) {
        if (params.value === 'Untagged') {
          return { backgroundColor: 'lightsalmon' }
        }
      }
    }
    columnDefs.push({ ...dynamicProperties, ...cellProperties })
  })
  return { defaultColDef, columnDefs }
}
