import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InputWrapperStyle, TopLabelStyle } from './StyleComponents'
import Select from '../../lib/SelectComponent'
import filterActions from '../../actions/filterActions'
import { Column, Row } from '../../lib/Grid'

const confidenceOption = [
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' }
]

const statusOption = [
  { label: 'Finalized', value: 'Finalized' },
  { label: 'Non Finalized', value: 'Non Finalized' }
]

class ImageFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confidenceValue: '',
      statusValue: ''
    }
  }

  onSubCategoryFilter = (val) => {
    if (val) {
      this.props.setSubCategory(val)
    } else {
      this.props.setSubCategory({ label: '', value: '' })
    }
    var ref = this
      setTimeout(function() {
        ref.props.setImagePage(1)
      }, 0)
  }

  onAttributeTypeSelect = (val) => {
    if (val) {
      this.props.setAttributeType(val)
    } else {
      this.props.setAttributeType({ label: '', value: '' })
    }
    this.props.setAttributeValue({ label: '', value: '' })
    var ref = this
    if (this.props.classValue.value) {
      setTimeout(function() {
        ref.props.setImagePage(1)
      }, 0)
    }
  }

  onAttributeValueSelect = (val) => {
    if (val) {
      this.props.setAttributeValue(val)
    } else {
      this.props.setAttributeValue({ label: '', value: '' })
    }
    var ref = this
    setTimeout(function() {
      ref.props.setImagePage(1)
    }, 0)
  }

  onConfidenceValueSelect = (val) => {
    if (val) {
      this.props.setConfidenceValue(val)
    } else {
      this.props.setConfidenceValue({ label: '', value: '' })
    }
    var ref = this
    setTimeout(function() {
      ref.props.setImagePage(1)
    }, 0)
  }

  onStatusValueSelect = (val) => {
    if (val) {
      this.props.setStatusValue(val)
    } else {
      this.props.setStatusValue({ label: '', value: '' })
    }
    var ref = this
    setTimeout(function() {
      ref.props.setImagePage(1)
    }, 0)
  }

  render() {
    return (
      <Row style={{ marginTop: '0.5rem' }}>
        <Column xs="6" sm="3.5" md="1.5">
          <InputWrapperStyle>
            <TopLabelStyle>Sub Category</TopLabelStyle>
            <Select
              closeMenuOnSelect={true}
              isDisabled={!this.props.category.value}
              isClearable
              value={
                this.props.subCategory.value === '' && this.props.subCategoriesList.length
                  ? null
                  : this.props.subCategory
              }
              placeholder={'Select'}
              options={this.props.subCategoriesList || []}
              onChange={(val) => this.onSubCategoryFilter(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="3.5" md="1.5">
          <InputWrapperStyle>
            <TopLabelStyle>Attribute Type*</TopLabelStyle>
            <Select
              closeMenuOnSelect={true}
              isDisabled={!this.props.attributeTypeList.length}
              isClearable
              value={
                this.props.attributeType.value === '' && this.props.attributeTypeList.length
                  ? null
                  : this.props.attributeType
              }
              placeholder={'Select'}
              options={this.props.attributeTypeList || []}
              onChange={(val) => this.onAttributeTypeSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="3.5" md="1.5">
          <InputWrapperStyle>
            <TopLabelStyle>Attribute Value</TopLabelStyle>
            <Select
              isDisabled={!this.props.attributeType.value}
              closeMenuOnSelect={true}
              isClearable
              value={
                this.props.attributeValue.value === '' && this.props.attributeType.value
                  ? null
                  : this.props.attributeValue
              }
              placeholder={'Select'}
              options={this.props.attributeValueListProjectLevel || []}
              onChange={(val) => this.onAttributeValueSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="3.5" md="1.5" style={{ marginLeft: '10rem' }}>
          <InputWrapperStyle>
            <TopLabelStyle>Confidence Level</TopLabelStyle>
            <Select
              isDisabled={!this.props.attributeType.value || !(this.props.isGridView === 'Grid View')}
              closeMenuOnSelect={true}
              isClearable
              value={this.props.confidenceLevel.value === '' ? null : this.props.confidenceLevel}
              placeholder={'Select'}
              options={confidenceOption}
              onChange={(val) => this.onConfidenceValueSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="3.5" md="1.5">
          <InputWrapperStyle>
            <TopLabelStyle>Status</TopLabelStyle>
            <Select
              isDisabled={!this.props.attributeType.value || !(this.props.isGridView === 'Grid View')}
              closeMenuOnSelect={true}
              isClearable
              value={this.props.status.value === '' ? null : this.props.status}
              placeholder={'Select'}
              options={statusOption}
              onChange={(val) => this.onStatusValueSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
      </Row>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    department: store.filtersReducer.department,
    classValue: store.filtersReducer.classValue,
    vendor: store.filtersReducer.vendor,
    category: store.filtersReducer.category,
    subCategory: store.filtersReducer.subCategory,
    attributeType: store.filtersReducer.attributeType,
    confidenceLevel: store.filtersReducer.confidenceLevel,
    status: store.filtersReducer.status,
    attributeValue: store.filtersReducer.attributeValue,
    departmentsList: store.filtersReducer.departmentsList,
    classesList: store.filtersReducer.classesList,
    vendorsList: store.filtersReducer.vendorsList,
    categoriesList: store.filtersReducer.categoriesList,
    subCategoriesList: store.filtersReducer.subCategoriesList,
    attributeTypeList: store.filtersReducer.attributeTypeList,
    attributeValueList: store.filtersReducer.attributeValueList,
    attributeValueListProjectLevel: store.filtersReducer.attributeValueListProjectLevel,
    isGridView: store.mainReducer.isGridView
  }
}

const mapDispatchToProps = {
  getDepartmentsData: filterActions.getDepartmentsData,
  getClassesData: filterActions.getClassesData,
  getVendorsData: filterActions.getVendorsData,
  setDepartment: filterActions.setDepartment,
  setClasses: filterActions.setClasses,
  setVendor: filterActions.setVendor,
  setCategory: filterActions.setCategory,
  setSubCategory: filterActions.setSubCategory,
  setAttributeType: filterActions.setAttributeType,
  setAttributeValue: filterActions.setAttributeValue,
  setConfidenceValue: filterActions.setConfidenceValue,
  setStatusValue: filterActions.setStatusValue,
  getAttributes: filterActions.getAttributes,
  getSubCategoriesData: filterActions.getSubCategoriesData
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageFilters)
