import api from '../helpers'

const filterActions = {
  setDepartment: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_DEPARTMENT',
        payload: data
      })

      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { department: data && data.length && data.map((el) => el.value) }
      })
    }
  },
  setClasses: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_CLASSES',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { class_desc: data.value }
      })
    }
  },
  setVendor: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_VENDOR',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { vendor_desc: data.value }
      })
    }
  },
  setCategory: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_CATEGORY',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { category: data.value }
      })
    }
  },
  setSubCategory: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_SUB_CATEGORY',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { sub_category: data.value }
      })
    }
  },
  setImageCount: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_IAMGE_COUNT',
        payload: data
      })
    }
  },
  setAttributeType: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_ATTRIBUTE_TYPE',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { attributeType: data.value }
      })
    }
  },
  setAttributeValue: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_ATTRIBUTE_VALUE',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { attributeValue: data.value }
      })
    }
  },
  setConfidenceValue: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_CONFIDENCE_VALUE',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { confidenceLevel: data.value }
      })
    }
  },
  setStatusValue: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_STATUS_VALUE',
        payload: data
      })
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: {
          status:
            data.value === 'Finalized' ? 'finalized' : data.value === '' ? '' : 'non-finalized'
        }
      })
    }
  },
  setStatusValueGridView: () => {
    return (dispatch) => {
      dispatch({
        type: 'SET_FILTER_DATA',
        payload: { status: 'non-finalized' }
      })
    }
  },
  getClassesData: (params) => {
    return (dispatch) => {
      api({
        url: '/getClasses',
        method: 'GET',
        params: params
      })
        .then((resp) => {
          dispatch({
            type: 'GET_CLASSES_LIST',
            payload: resp.data.classData
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  getDepartmentsData: (reqObj) => {
    return (dispatch) => {
      api({
        url: '/getDepartments',
        method: 'POST',
        data: reqObj
      })
        .then((resp) => {
          dispatch({
            type: 'GET_DEPARTMENTS_LIST',
            payload: resp.data.departmentData
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  getVendorsData: (reqObj) => {
    return (dispatch) => {
      api({
        url: '/getVendors',
        method: 'POST',
        data: reqObj
      })
        .then((resp) => {
          dispatch({
            type: 'GET_VENDORS_LIST',
            payload: resp.data.vendorData
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  getCategoriesData: (reqObj) => {
    return (dispatch) => {
      api({
        url: '/getCategories',
        method: 'POST',
        data: reqObj
      })
        .then((resp) => {
          dispatch({
            type: 'GET_CATEGORIES_LIST',
            payload: resp.data.categoryData
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  clearCategoriesData: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_CATEGORIES_LIST'
      })
    }
  },
  getSubCategoriesData: (reqObj) => {
    return (dispatch) => {
      api({
        url: '/getSubCategories',
        method: 'POST',
        data: reqObj
      })
        .then((resp) => {
          dispatch({
            type: 'GET_SUB_CATEGORIES_LIST',
            payload: resp.data.subCategoryData
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  getAttributes: (params) => {
    return (dispatch) => {
      return api({
        url: '/getAttributesNew',
        method: 'POST',
        data: params
      })
        .then((resp) => {
          dispatch({
            type: 'GET_ATTRIBUTE_TYPE_LIST',
            payload: resp.data.attributeTypes
          })
          dispatch({
            type: 'GET_ATTRIBUTE_OPTIONS',
            payload: resp.data.attributeOptions
          })
          dispatch({
            type: 'GET_ATTRIBUTE_OPTIONS_PROJECT_LEVEL',
            payload: resp.data.attributeOptionsProjectLevel
          })
          dispatch({
            type: 'GET_OVERALL_CATEGORY_OPTIONS',
            payload: resp.data.categoryOptions
          })
          return resp
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  getDownloadAttributes: payload => {
    return dispatch => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: "/image/downloadAttributes",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch((err) => {
          console.log(err)
        })
    };
  },
}

export default filterActions
