import React from 'react'
import Pagination from '@material-ui/lab/Pagination'

const Paginate = ({ pageNumber, pageCount, setPage }) => {
  const handleChange = (event, value) => {
    setPage(value)
  }
  return (
    // <div className={classes.root}>
    <Pagination
      size="small"
      count={pageCount}
      variant="outlined"
      shape="rounded"
      page={pageNumber}
      onChange={handleChange}
    />
    // </div>
  )
}

export default Paginate
