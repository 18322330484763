import Checkbox from '@material-ui/core/Checkbox'
import { omitBy, cloneDeep } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import imageActions from '../../actions/imageActions'
import ReactImageMagnify from 'react-image-magnify'
import Select from 'react-dropdown-select'
class ImageComponent extends React.Component {
  state = {
    checked: this.props.checkedIds[this.props.id] || false
  }

  handleChange = (e) => {
    e && e.preventDefault()
    this.setState(
      {
        checked: !this.state.checked
      },
      function() {
        if (this.state.checked) {
          this.props.setCheckedOnImage(this.props.id, true)
          this.props.setFinalizeAttribute(
            this.props.id,
            this.props.attributeType.value,
            'finalized'
          )
        } else {
          this.props.setCheckedOnImage(this.props.id, false)
          this.props.setFinalizeAttribute(
            this.props.id,
            this.props.attributeType.value,
            'non-finalized'
          )
        }
      }
    )
  }

  onChangeAttributeValue = (id, val) => {
    let initial = omitBy(
      this.props.attribute,
      (value, key) => !key.startsWith(this.props.attributeType.value)
    )
    let iniValObj = Object.values(initial)
    let iniValAttr = iniValObj[0].attribute_value
    let attrObj = cloneDeep(iniValObj)
    attrObj[0].attribute_value = val[0].value
    if (val && val[0]) {
      this.props.setAttributeValue(id, this.props.attributeType.value, attrObj, iniValAttr)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ checked: this.props.checkedIds[this.props.id] || false })
    }
  }

  render() {
    const { url, id } = this.props
    let { checked } = this.state
    const imageProps = {
      smallImage: {
        alt: '',
        isFluidWidth: true,
        src: url
      },
      largeImage: {
        src: url,
        width: 800,
        height: 1200
      },
      enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
    }
    return (
      <div
        style={{
          position: 'relative'
        }}>
        <div
          style={{
            margin: '0.5rem',
            padding: '8px 0',
            position: 'relative'
          }}>
          {this.props.labelStatus === 'finalized' ? (
            <div
              style={{
                width: '6rem',
                backgroundColor: 'green',
                fontSize: '1.25rem',
                textTransform: 'capitalize',
                color: '#fff',
                padding: '0.25rem',
                textAlign: 'center'
              }}>
              {this.props.labelStatus}
            </div>
          ) : (
            ''
          )}
          {this.props.labelStatus === 'updated' ? (
            <div
              style={{
                width: '6rem',
                backgroundColor: 'orange',
                fontSize: '1.25rem',
                textTransform: 'capitalize',
                color: '#fff',
                padding: '0.25rem',
                textAlign: 'center'
              }}>
              {this.props.labelStatus}
            </div>
          ) : (
            ''
          )}
          <div style={{ position: 'absolute', right: '0', top: '0' }}>
            <Checkbox color="primary" checked={checked} value={id} onClick={this.handleChange} />
          </div>
          <ReactImageMagnify
            {...imageProps}
            className="overlay-image"
            enlargedImageContainerClassName="enlarged-image"
            hoverDelayInMs="500"
          />
          <div
            style={{
              fontSize: '0.75rem',
              margin: '0 auto',
              whiteSpace: 'nowrap',
              width: '14rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {this.props.image_description}
          </div>
          <div style={{ width: '14rem', margin: '14px auto' }}>
            <Select
              closeOnSelect={true}
              clearOnSelect={true}
              dropdownPosition={'auto'}
              value={this.props.selectedAttrValues[this.props.id]}
              options={this.props.attributeValueList || []}
              onChange={(values) => this.onChangeAttributeValue(this.props.id, values)}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapActionsToProps = {
  setCheckedOnImage: imageActions.setCheckedOnImage,
  setAttributeValue: imageActions.setAttributeValue,
  setFinalizeAttribute: imageActions.setFinalizeAttribute
}

const mapStateToProps = (store) => {
  return {
    checkedIds: store.imageReducer.checkedIds,
    attributeType: store.filtersReducer.attributeType,
    attributeValueList: store.filtersReducer.attributeValueList,
    selectedAttrValues: store.imageReducer.selectedAttrValues
  }
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ImageComponent))
