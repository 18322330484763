const filtersReducerDefaultState = {
  departmentsList: [],
  classesList: [],
  vendorsList: [],
  categoriesList: [],
  subCategoriesList: [],
  attributeTypeList: [],
  attributeValueList: [],
  attributeValueListProjectLevel: [],
  attributeOptions: {},
  categoryOptionOverallLevel: {},
  attributeOptionsProjectLevel: {},
  department: '',
  classValue: { value: '', label: '' },
  vendor: { value: '', label: '' },
  category: { value: '', label: '' },
  subCategory: { value: '', label: '' },
  noOfImages: { value: '100', label: '100' },
  attributeType: { value: '', label: '' },
  attributeValue: { value: '', label: '' },
  confidenceLevel: { value: '', label: '' },
  status: '',
  filterData: {
    department: '',
    class_desc: '',
    vendor_desc: '',
    category: '',
    sub_category: '',
    attributeType: '',
    attributeValue: '',
    confidenceLevel: '',
    status: ''
  }
}

export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_DEPARTMENTS_LIST':
      return {
        ...state,
        departmentsList: action.payload
      }

    case 'GET_CLASSES_LIST':
      return {
        ...state,
        classesList: action.payload
      }
    case 'GET_VENDORS_LIST':
      return {
        ...state,
        vendorsList: action.payload
      }
    case 'GET_CATEGORIES_LIST':
      return {
        ...state,
        categoriesList: action.payload
      }
    case 'CLEAR_CATEGORIES_LIST':
      return {
        ...state,
        categoriesList: []
      }
    case 'GET_SUB_CATEGORIES_LIST':
      return {
        ...state,
        subCategoriesList: action.payload
      }
    case 'GET_ATTRIBUTE_TYPE_LIST':
      return {
        ...state,
        attributeTypeList: action.payload
      }
    case 'GET_ATTRIBUTE_VALUE_LIST':
      return {
        ...state,
        attributeValueList: action.payload
      }
    case 'GET_ATTRIBUTE_OPTIONS':
      return {
        ...state,
        attributeOptions: action.payload
      }
    case 'GET_ATTRIBUTE_OPTIONS_PROJECT_LEVEL':
      return {
        ...state,
        attributeOptionsProjectLevel: action.payload
      }
    case 'GET_OVERALL_CATEGORY_OPTIONS':
      return {
        ...state,
        categoryOptionOverallLevel: action.payload
      }
    case 'SET_DEPARTMENT':
      return {
        ...state,
        department: action.payload
      }

    case 'SET_CLASSES':
      return {
        ...state,
        classValue: {
          ...state.classValue,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_VENDOR':
      return {
        ...state,
        vendor: {
          ...state.vendor,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_CATEGORY':
      return {
        ...state,
        category: {
          ...state.category,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_SUB_CATEGORY':
      return {
        ...state,
        subCategory: {
          ...state.subCategory,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_IAMGE_COUNT':
      return {
        ...state,
        noOfImages: {
          ...state.noOfImages,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_ATTRIBUTE_TYPE':
      let attr_value_list = state.attributeOptions[action.payload.value]
      let attr_value_list_project_level = state.attributeOptionsProjectLevel[action.payload.value]
      return {
        ...state,
        attributeType: {
          ...state.attributeType,
          value: action.payload.value,
          label: action.payload.label
        },
        attributeValueList: attr_value_list,
        attributeValueListProjectLevel: attr_value_list_project_level
      }
    case 'SET_ATTRIBUTE_VALUE':
      return {
        ...state,
        attributeValue: {
          ...state.attributeValue,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_CONFIDENCE_VALUE':
      return {
        ...state,
        confidenceLevel: {
          ...state.confidenceLevel,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_STATUS_VALUE':
      return {
        ...state,
        status: {
          ...state.confidenceLevel,
          value: action.payload.value,
          label: action.payload.label
        }
      }
    case 'SET_FILTER_DATA':
      return {
        ...state,
        filterData: {
          ...state.filterData,
          ...action.payload
        }
      }
    default:
      return state
  }
}
