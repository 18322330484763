const planReducerDefaultState = {
  planlist: [],
  planCount: 0,
  deleteMsg: '',
  pageNumber: '',
  planId: '',
  planNotification: {
    planNameError: '',
    excelUploadError: ''
  }
}

export default (state = planReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_PLAN_LIST':
      return {
        ...state,
        planlist: action.payload
      }
    case 'SET_PLAN_COUNT':
      return {
        ...state,
        planCount: action.payload
      }
    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: action.payload
      }
    case 'SET_PLAN_ID':
      return {
        ...state,
        planId: action.payload
      }
    case 'ADDTO_PLAN_LIST':
      return {
        ...state,
        planlist: [...state.planlist, action.payload]
      }
    case 'CREATE_PLAN':
      return {
        ...state,
        planlist: [action.payload, ...state.planlist.slice(0, state.planlist.length - 1)]
      }
    case 'UPDATE_PLAN':
      let other_plans = state.planlist.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        planlist: [action.payload, ...other_plans]
      }
    case 'CLEAR_PLAN_DATA':
      return {
        ...planReducerDefaultState
      }
    case 'PLAN_NAME_ERROR':
      return {
        ...state,
        planNotification: {
          ...state.planNotification,
          planNameError: action.payload
        }
      }
    case 'EXCEL_UPLOAD_ERROR':
      return {
        ...state,
        planNotification: {
          ...state.planNotification,
          excelUploadError: action.payload
        }
      }
    case 'DELETE_PLAN':
      return {
        ...state,
        deleteMsg: action.payload
      }
    default:
      return state
  }
}
