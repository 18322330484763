import React from 'react'
import { connect } from 'react-redux'
import { omitBy, cloneDeep } from 'lodash'
import { withRouter } from 'react-router-dom'
import imageActions from '../../actions/imageActions'
import filterActions from '../../actions/filterActions'
import commonActions from '../../actions'
import { Row, Column } from '../../lib/Grid'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'

class ImageSelector extends React.Component {
  state = {
    selectedValue: ''
  }

  componentWillUnmount() {
    this.props.clearSetAttributeValue()
    this.props.clearCheckedOnImage()
    this.props.clearFinalizeAttribute()
    this.props.setStatusValue({ label: '', value: '' })
    this.props.setConfidenceValue({ label: '', value: '' })
    this.props.setIsGridView('')
  }

  onSubmitAttributeValues = (e) => {
    e && e.preventDefault()
    const filterData = {
      department: this.props.department.value,
      class_desc: this.props.classValue.value,
      category: this.props.category.value,
      vendor_desc: this.props.vendor.value
    }
    const payload = { imageAttributeObj: this.props.selectedAttrValues, updateType: 'grid' }
    return this.props.changeAttributeValues(payload).then((resp) => {
      if (resp.success) {
        toast.success(resp.data.message)
        this.props.clearSetAttributeValue()
        this.setState({ selectedValue: '' })
        this.props.getAttributes({
          projectId: this.props.planid,
          filterData
        })
        this.props.getSubCategoriesData({
          projectId: this.props.planid,
          filterData
        })
        this.props.getImageList({
          page: this.props.pageNumber,
          pageCount: this.props.noOfImages.value,
          projectId: this.props.planid,
          filterData: this.props.filterData
        })
      }
    })
  }

  onFinalizeImages = (e) => {
    e && e.preventDefault()
    let selectedFinalizeAttrCopy = cloneDeep(this.props.selectedFinalizeAttr)
    let attributeType = Object.keys(Object.values(selectedFinalizeAttrCopy)[0])[1]
    let result = omitBy(
      selectedFinalizeAttrCopy,
      (value, key) => value[attributeType]['status'] !== 'finalized'
    )
    this.props.finalizeImages(result).then((resp) => {
      if (resp.success) {
        toast.success('Successfully Finalized Images')
        this.props.clearCheckedOnImage()
        this.props.clearFinalizeAttribute()
        this.props.clearCheckBox()
        this.setState({ selectedValue: '' })
        this.props.getImageList({
          page: this.props.pageNumber,
          pageCount: this.props.noOfImages.value,
          projectId: this.props.planid,
          filterData: this.props.filterData
        }).then(() => {
          if(this.props.imagelist && this.props.imagelist.length === 0){
            this.props.getImageList({
              page: 1,
              pageCount: this.props.noOfImages.value,
              projectId: this.props.planid,
              filterData: this.props.filterData
            })
          }
        })
      }
    })
  }

  updateFinalizeImages = () => {
    this.onSubmitAttributeValues().then((resp) => {
      this.onFinalizeImages()
    })
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Column md="11"></Column>
          <Column md="1">
            <Button
              disabled={!Object.keys(this.props.selectedAttrValues).length}
              color="primary"
              variant="contained"
              onClick={this.onSubmitAttributeValues}>
              Update
            </Button>
          </Column>
          <Column md="1">
            <Button
              disabled={Object.values(this.props.checkedIds).indexOf(true) === -1}
              color="primary"
              variant="contained"
              onClick={Object.keys(this.props.selectedAttrValues).length ? this.updateFinalizeImages : this.onFinalizeImages}>
              Finalize
            </Button>
          </Column>
        </Row>
      </React.Fragment>
    )
  }
}

const mapActionsToProps = {
  getImageList: imageActions.getImageList,
  clearCheckBox: imageActions.clearCheckBox,
  setCheckedOnImage: imageActions.setCheckedOnImage,
  clearCheckedOnImage: imageActions.clearCheckedOnImage,
  changeAttributeValues: imageActions.changeAttributeValues,
  finalizeImages: imageActions.finalizeImages,
  clearSetAttributeValue: imageActions.clearSetAttributeValue,
  clearFinalizeAttribute: imageActions.clearFinalizeAttribute,
  getAttributes: filterActions.getAttributes,
  setStatusValue: filterActions.setStatusValue,
  setIsGridView: commonActions.setIsGridView,
  setConfidenceValue: filterActions.setConfidenceValue,
  getSubCategoriesData: filterActions.getSubCategoriesData
}

const mapStateToProps = (store) => {
  return {
    department: store.filtersReducer.department,
    classValue: store.filtersReducer.classValue,
    category: store.filtersReducer.category,
    vendor: store.filtersReducer.vendor,
    filterData: store.filtersReducer.filterData,
    checkedIds: store.imageReducer.checkedIds,
    attributeValue: store.filtersReducer.attributeValue,
    attributeValueList: store.filtersReducer.attributeValueList,
    noOfImages: store.filtersReducer.noOfImages,
    attributeType: store.filtersReducer.attributeType,
    selectedAttrValues: store.imageReducer.selectedAttrValues,
    selectedFinalizeAttr: store.imageReducer.selectedFinalizeAttr,
    imagelist: store.imageReducer.imagelist
  }
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ImageSelector))
