import axios from 'axios'
import { PRODUCTION_ENDPOINT, DEVELOPMENT_ENDPOINT, QA_ENDPOINT } from '../constants/endpoints'

function getDefaultUrl(env = 'development') {
  switch (env.toLowerCase()) {
    case 'production':
      return PRODUCTION_ENDPOINT
    case 'development':
      return DEVELOPMENT_ENDPOINT
    case 'qa':
      return QA_ENDPOINT
    default:
      return DEVELOPMENT_ENDPOINT
  }
}

axios.defaults.baseURL = getDefaultUrl(process.env.NODE_ENV)

export default function api({ url, method, data = null, params = {} }) {
  const requestConfig = data === null ? { url, method, params } : { url, method, data, params }
  console.log(`API Name : ${url} with data`)
  console.log(data)
  return new Promise((resolve, reject) => {
    axios
      .request(requestConfig)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
