import React from 'react'
import { connect } from 'react-redux'
import Paginate from '../../components/Paginate'
import { Column, Row } from '../../lib/Grid'
import filterActions from '../../actions/filterActions'
import imageActions from '../../actions/imageActions'
import { withRouter } from 'react-router-dom'
import ImageComponent from '../../components/ImageComponent'
import { InputWrapperStyle, TopLabelStyle } from './StyleComponents'
import Select from '../../lib/SelectComponent'
import Checkbox from '@material-ui/core/Checkbox'

const ImagesCount = [
  { label: '20', value: '20' },
  { label: '40', value: '40' },
  { label: '60', value: '60' },
  { label: '80', value: '80' },
  { label: '100', value: '100' }
]

class ImageGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.noOfImages.value !== this.props.noOfImages.value) {
      if (this.props.category && this.props.category.value) {
        this.props.getImageList({
          page: this.props.pageNumber,
          pageCount: this.props.noOfImages.value,
          projectId: this.props.planid,
          filterData: this.props.filterData
        })
      }
    }
    if (prevProps.imagelist !== this.props.imagelist || prevProps.checkedIds !== this.props.checkedIds) {
      const selectAllFlag = this.props.imagelist && this.props.imagelist.length && this.props.imagelist.every((img) => {
        return this.props.checkedIds && this.props.checkedIds[img.id]
      })
      this.setState({
        selectAll : selectAllFlag
      })
    }
    // if (prevProps.clearCheckBoxVal !== this.props.clearCheckBoxVal) {
    //   this.setState({
    //     selectAll: false
    //   })
    // }
    // if (prevProps.pageNumber !== this.props.pageNumber) {
    //   this.setState({
    //     selectAll: false
    //   })
    // }
  }
  handleSelectAll = () => {
    this.setState(
      {
        selectAll: !this.state.selectAll
      },
      () => this.selectAllImage()
    )
  }
  selectAllImage = () => {
    this.props.imagelist.forEach((img) => {
      if (this.state.selectAll) {
        this.props.setCheckedOnImage(img.id, true)
        this.props.setFinalizeAttribute(img.id, this.props.attributeType.value, 'finalized')
      } else {
        this.props.setCheckedOnImage(img.id, false)
        this.props.setFinalizeAttribute(img.id, this.props.attributeType.value, 'non-finalized')
      }
    })
  }
  onChangeImageCount = (val) => {
    if (val) {
      this.props.setImageCount(val)
    }
  }
  render() {
    let imagelist = this.props.imagelist
    let { imageCount, pageNumber } = this.props
    let pageCount =
      imageCount % this.props.noOfImages.value === 0
        ? Number(Math.floor(imageCount / this.props.noOfImages.value))
        : Number(Math.floor(imageCount / this.props.noOfImages.value)) + 1
    // let startIndex = (pageNumber - 1) * 10 + 1
    // let lastIndex = imageCount - startIndex < 9 ? imageCount : startIndex + 9
    return (
      <React.Fragment>
        <div
          onClick={this.handleSelectAll}
          style={{
            marginTop: '2rem',
            cursor: 'pointer',
            width: '7rem',
            height: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          Select All
          <Checkbox color="primary" checked={this.state.selectAll} />
        </div>
        <div
          className="grid-main"
          style={{
            height: '630px',
            border: '1px solid #dadada',
            margin: '8px 0',
            padding: '14px 0',
            overflowY: 'auto'
          }}>
          {imagelist.length ? (
            <>
              <Row margin_bottom="20px" style={{ flexWrap: 'wrap' }}>
                {imagelist &&
                  imagelist.map((item, index) => {
                    return (
                      <Column xs="12" sm="1" md="2.4" key={item.id} style={{ flex: '0 0 20%' }}>
                        <ImageComponent
                          url={item.url}
                          id={item.id}
                          attribute={item.attribute}
                          image_description={item.image_description}
                          selectAll={this.state.selectAll}
                          labelStatus={item.attribute_label_status}
                        />
                      </Column>
                    )
                  })}
              </Row>
            </>
          ) : (
            <div style={{ textAlign: 'center', paddingTop: '190px' }}>
              Please filter Department and Class to view images
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginBottom: '7rem' }}>
            <InputWrapperStyle>
              <TopLabelStyle>No of Images/Page</TopLabelStyle>
              <Select
                closeMenuOnSelect={true}
                isClearable
                value={this.props.noOfImages}
                placeholder={'Select'}
                options={ImagesCount || []}
                onChange={(val) => this.onChangeImageCount(val)}
              />
            </InputWrapperStyle>
          </div>
          <div style={{ marginTop: '2rem' }}>
            <Paginate
              pageNumber={pageNumber}
              pageCount={pageCount}
              setPage={this.props.setImagePage}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    department: store.filtersReducer.department,
    classValue: store.filtersReducer.classValue,
    clearCheckBoxVal: store.imageReducer.clearCheckBoxVal,
    vendor: store.filtersReducer.vendor,
    category: store.filtersReducer.category,
    filterData: store.filtersReducer.filterData,
    attributeType: store.filtersReducer.attributeType,
    attributeValue: store.filtersReducer.attributeValue,
    departmentsList: store.filtersReducer.departmentsList,
    classesList: store.filtersReducer.classesList,
    vendorsList: store.filtersReducer.vendorsList,
    attributeTypeList: store.filtersReducer.attributeTypeList,
    attributeValueList: store.filtersReducer.attributeValueList,
    noOfImages: store.filtersReducer.noOfImages,
    attributeValueListProjectLevel: store.filtersReducer.attributeValueListProjectLevel,
    attributeType: store.filtersReducer.attributeType,
    checkedIds: store.imageReducer.checkedIds
  }
}

const mapDispatchToProps = {
  setCheckedOnImage: imageActions.setCheckedOnImage,
  setFinalizeAttribute: imageActions.setFinalizeAttribute,
  getImageList: imageActions.getImageList,
  getDepartmentsData: filterActions.getDepartmentsData,
  getClassesData: filterActions.getClassesData,
  getVendorsData: filterActions.getVendorsData,
  setDepartment: filterActions.setDepartment,
  setClasses: filterActions.setClasses,
  setVendor: filterActions.setVendor,
  setImageCount: filterActions.setImageCount,
  setAttributeType: filterActions.setAttributeType,
  setAttributeValue: filterActions.setAttributeValue,
  getAttributes: filterActions.getAttributes
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImageGrid))
