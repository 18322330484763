import XLSX from 'xlsx'

export const imageExists = (image_url) => {
  var http = new XMLHttpRequest()

  http.open('HEAD', image_url, false)
  http.send()

  return http.status !== 404
}

export const is_url_string = (str) => {
  let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export const get_header_row = (sheet) => {
  let headers = []
  let range = XLSX.utils.decode_range(sheet['!ref'])
  let C,
    sR = range.s.r /* start in the first row */
  /* walk every column in the range */
  let headerRow
  for (let R = sR; R < range.e.r; ++R) {
    let cell = sheet[XLSX.utils.encode_cell({ c: 0, r: R })]
    if (cell && cell.t) {
      headerRow = R
      break
    } else {
      continue
    }
  }

  if (headerRow === undefined) {
    let error_obj = {
      headerData: [],
      error_msg:
        'Unable to find header Row . Please download the sample format for reference and upload again'
    }
    return error_obj
  }
  for (C = range.s.c; C <= range.e.c; ++C) {
    let cell =
      sheet[XLSX.utils.encode_cell({ c: C, r: headerRow })] /* find the cell in the first row */

    let hdr = 'UNKNOWN ' + C // <-- replace with your desired default

    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
    else continue
    hdr = hdr.replace(/[^A-Z0-9]/gi, '')
    hdr = hdr.toLowerCase()
    headers.push(hdr)
  }

  let return_obj = {
    headerData: headers,
    error_msg: ''
  }
  return return_obj
}
