export const PRODUCTION_ENDPOINT = '/api'

export const DEVELOPMENT_ENDPOINT = '/api'

export const QA_ENDPOINT = '/api'


// General : "/api"
// Local : "http://localhost:5000/api"
// Dev : ""
// Stage:""
// Stage : ""
// prod : ""
