import api from '../helpers'
import {cloneDeep} from 'lodash'
const imageActions = {
  uploadImageFromExcel: (payload) => {
    return (dispatch) => {
      return api({
        url: '/img/uploadImageFromExcel',
        method: 'POST',
        data: payload
      })
        .then((resp) => {
          return resp
        })
        .catch((err) => {
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          console.log('image upload error', err)
          return err
        })
    }
  },
  getImageList: (reqdata) => {
    return (dispatch) => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: '/image/getImageListNew',
        method: 'POST',
        data: reqdata
      })
        .then((resp) => {
          if (resp.data) {
            dispatch({
              type: 'SET_IMAGE_LIST',
              payload: resp.data.images
            })
            dispatch({
              type: 'SET_IMAGE_COUNT',
              payload: resp.data.count
            })
            dispatch({
              type: 'LOADER_STATE',
              payload: {
                status: false
              }
            })
            return resp
          }
        })
        .catch((err) => {
          dispatch({
            type: 'SET_IMAGE_LIST',
            payload: []
          })
          dispatch({
            type: 'SET_IMAGE_COUNT',
            payload: 0
          })
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: false
            }
          })
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          console.log('No result found error', err)
          return err
        })
      // later call api here to fetch api list  change the below into axios async call;
    }
  },
  setCheckedOnImage: (imageId, flag) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_CHECKED',
        payload: { imageId, flag }
      })
    }
  },
  setAttributeValue: (id, attrType, attrVal, iniValAttr) => {
    let attVal1 = cloneDeep(attrVal)
    if(attVal1[0].attribute_value !== attVal1[0].last_updated_value){
    attVal1[0].status = 'updated'
    }
    return (dispatch) => {
      dispatch({
        type: 'SET_ATTRIBUTE_VAL',
        payload: {
          [id]: {
            id: id,
            attr_data: { [attrType]: { ...attVal1[0],  last_updated_value: iniValAttr } }
          }
        }
      })
    }
  },
  setFinalizeAttribute: (id, attrType, isfinalized) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_FINALIZE_ATTRIBUTE',
        payload: { [id]: { id: id, [attrType]: { status: isfinalized }  } }
      })
    }
  },
  clearFinalizeAttribute: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_FINALIZE_ATTRIBUTE'
      })
    }
  },
  clearSetAttributeValue: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_ATTRIBUTE_VAL'
      })
    }
  },
  clearImageAttrMapping: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_IMAGE_ATTR_MAPPING'
      })
    }
  },
  setImageList: (imageList) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_IMAGE_LIST',
        payload: imageList
      })
    }
  },
  clearCheckedOnImage: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_CHECKED'
      })
    }
  },
  clearCheckBox: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_CHECK_BOX'
      })
    }
  },
  clearImageCount: () => {
    return (dispatch) => {
      dispatch({
        type: 'CLEAR_IMAGE_COUNT'
      })
    }
  },
  changeAttributeValues: (reqdata) => {
    return (dispatch) => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: '/image/changeAttributeValueNew',
        method: 'POST',
        data: reqdata
      })
        .then((resp) => {
          if (resp.data) {
            return resp
          }
        })
        .catch((err) => {
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: true
            }
          })
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          console.log('Error updating data', err)
          return err
        })
    }
  },
  finalizeImages: (reqdata) => {
    return (dispatch) => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: '/image/finalizeImages',
        method: 'POST',
        data: reqdata
      })
        .then((resp) => {
          if (resp.success) {
            return resp
          }
        })
        .catch((err) => {
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: true
            }
          })
          console.log('Error updating data', err)
          return err
        })
    }
  },
  changeOverAllCategoriesValues: (reqdata) => {
    return (dispatch) => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: 'image/changeCategoryOfImage',
        method: 'POST',
        data: reqdata
      })
        .then((resp) => {
          if (resp.data) {
            return resp
          }
        })
        .catch((err) => {
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: true
            }
          })
          console.log('Error updating data', err)
          return err
        })
    }
  },
}

export default imageActions
