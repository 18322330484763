import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      class: '',
      display: 'none',
      showMinipopup: true,
      showClose: true,
      hideArrow: false
    }
  }
  onClickShow = () => {
    this.setState({
      class: '',
      display: '',
      showClose: true,
      hideArrow: false
    })
  }
  MinipopupShow = () => {
    this.setState({
      showMinipopup: true
    })
  }
  hideMinipopup = () => {
    this.setState({
      class: 'active',
      display: 'inline-block',
      hideArrow: true,
      showClose: false
    })
  }

  render() {
    return (
      <nav id="sidebar" className={this.state.class}>
        <div className="sidebar-header" />
        <ul className="list-unstyled components sideBarList1">
          <li>
            <Tooltip title="Dashboard" arrow placement="right">
              <NavLink
                to="/dashboard"
                exact
                strict
                style={{ color: '#e2e2e2' }}
                activeStyle={{ color: '#2D69EB' }}>
                <i className="fa fa-tachometer fa-4x" aria-hidden="true" />{' '}
                <span className="hidetext fontstyling" style={{ display: this.state.display }}>
                  Dashboard
                </span>
              </NavLink>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Plan" arrow placement="right">
              <NavLink
                to="/plan/:id"
                exact
                strict
                style={{ color: '#e2e2e2' }}
                activeStyle={{ color: '#2D69EB' }}>
                <i className="fa fa-pencil-square-o fa-4x" aria-hidden="true" />{' '}
                <span className="hidetext fontstyling" style={{ display: this.state.display }}>
                  Plan
                </span>
              </NavLink>
            </Tooltip>
          </li> */}
          <div className="arrowBottom">
            {this.state.hideArrow ? (
              <i
                className="fa fa-angle-left innerIcoTop"
                aria-hidden="true"
                onClick={(e) => this.onClickShow(e)}
              />
            ) : null}
            {this.state.showClose ? (
              <div>
                <i
                  className="fa fa-angle-right innerIcoTop2"
                  aria-hidden="true"
                  onClick={() => {
                    this.hideMinipopup()
                  }}
                />{' '}
              </div>
            ) : null}
          </div>
        </ul>
      </nav>
    )
  }
}

export default SideBar
