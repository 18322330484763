import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import mainReducer from '../reducers'
import userReducer from '../reducers/userReducer'
import planReducer from '../reducers/planReducer'
import imageReducer from '../reducers/imageReducer'
import filtersReducer from '../reducers/filtersReducer'
const middleware = [thunk]

export default () => {
  const store = createStore(
    combineReducers({
      mainReducer,
      userReducer,
      planReducer,
      imageReducer,
      filtersReducer
    }),
    composeWithDevTools(applyMiddleware(...middleware))
  )
  return store
}
