import React from 'react'
import styled from 'styled-components'
import XLSX from 'xlsx'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DragAndDrop from '../../components/DragAndDrop'
import ProgressBar from '../../components/ProgressBar'
import TextField from '@material-ui/core/TextField'
import { runAttributeAlgo } from '../../socket.js'
import planActions from '../../actions/PlanActions'
import imageActions from '../../actions/imageActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import customToast from '../../lib/customToast'
import { is_url_string, get_header_row } from '../../utils/ExcelValidators'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UploadContainerStyle = styled.div`
  margin-top: 2rem;
  height: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`
const UploadBoxStyle = styled.div`
  margin: auto 0;
  height: 200px;
  width: 100%;
  border: 3px dotted rgba(212, 212, 212, 1);
  border-radius: 10px;
`

const ProgressBarContainerStyle = styled.div`
  height: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const dataSet = [
  {
    Image: "",
    "Sty/Clr ORIN": ""
  }
]

class Plan extends React.Component {
  state = {
    planName: '',
    image_data: [],
    progressBarObject: {
      counter: 0,
      max: 0
    }
  }

  uploadXLSXFile = (file) => {
    let formData = new FormData()

    formData.append('file', file)

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1])
    // }

    var reader = new FileReader()
    let readerPromise = new Promise((resolve, reject) => {
      reader.onload = function(e) {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary'
        })

        workbook.SheetNames.forEach(function(sheetName) {
          let sheet = workbook.Sheets[sheetName]

          let sheet_json = XLSX.utils.sheet_to_row_object_array(sheet)

          let header_text_data = get_header_row(sheet)
          let header_data

          if (header_text_data.error_msg === '') {
            header_data = header_text_data && header_text_data.headerData
            //validate header Data
            let predefined_required_header_data = {
              image: 0,
              styclrorin: 1
            }
            Object.keys(predefined_required_header_data).forEach((item) => {
              let predefined_item_index = predefined_required_header_data[item]
              let header_data_value_at_pre_index = header_data[predefined_item_index]

              if (header_data_value_at_pre_index !== item) {
                let header_validate_error = {
                  sheet_data: [],
                  error_msg:
                    'Wrong Header Format. Please download the sample format for reference and upload again '
                }
                resolve(header_validate_error)
              }
            })
          } else {
            let err_obj = {
              sheet_data: [],
              error_msg: header_text_data.error_msg
            }
            resolve(err_obj)
          }

          let modified_sheet = XLSX.utils.aoa_to_sheet([header_data])
          XLSX.utils.sheet_add_json(modified_sheet, sheet_json, { skipHeader: true, origin: 'A2' })

          let modified_sheet_json = XLSX.utils.sheet_to_row_object_array(modified_sheet)

          let result_obj = {
            sheet_data: modified_sheet_json,
            error_msg: ''
          }

          resolve(result_obj)
        })
      }

      reader.onerror = function(ex) {
        console.log(ex)
      }

      reader.readAsBinaryString(file)
    })

    readerPromise.then((data) => {
      if (data.error_msg === '') this.uploadXlsxData(data.sheet_data)
      else {
        customToast.error(data.error_msg)
      }
    })
  }

  uploadXlsxData = (data) => {
    let no_of_data_recieved_excel = data.length
    let filteredImageData = data
      .filter((item) => {
        let truth_value = typeof item['image'] !== 'undefined' && item['image'] !== ''

        let valid_url_string = is_url_string(item['image'])
        return truth_value && valid_url_string
      })
      .map((item) => {
        let image_name = item['image'].split('/')
        image_name = image_name[image_name.length - 1]
        // image_name = image_name[image_name.length - 1].split(".")[0];

        let obj = {
          url: item['image'],
          name: image_name,
          department: item['department'],
          vendor_desc: item['vendordescription'],
          style_vpn: item['stylevpn'],
          style_color_id: item['styclrorin'],
          fobus: item['fobus'],
          color_desc: item['colordescription'],
          class_desc: item['classdescription']
        }

        return obj
      })
    let no_of_filtered_data = filteredImageData.length
    if (no_of_filtered_data === 0) {
      customToast.error(
        'Empty data found in the excel .Please download the sample format for reference and upload again '
      )
      return
    } else if (no_of_filtered_data < no_of_data_recieved_excel) {
      customToast.warning('Images with invalid / empty links were found in the excel')
    }

    this.setState(
      {
        ...this.state,
        image_data: filteredImageData,
        progressBarObject: {
          ...this.state.progressBarObject,
          max: filteredImageData.length
        }
      },
      function() {
        if (this.state.planName !== '') {
          let planPayload = {
            plan_name: this.state.planName,
            num_of_images: this.state.image_data.length
          }
          this.props.createPlan(planPayload).then((res) => {
            if (res.success) {
              this.progressWithRequest(res)
            }
          })
        }
      }
    )
  }

  makeRequest = async (payload) => {
    await this.props.uploadImageFromExcel(payload).then((res) => {
      if (res.success) {
        this.setState({
          ...this.state,
          progressBarObject: {
            ...this.state.progressBarObject,
            counter: this.state.progressBarObject.counter + 1
          }
        })
      }
    })
  }
  progressWithRequest = async (res) => {
    for (let i = 0; i < this.state.image_data.length; i++) {
      let imagePayload = {
        image_data: this.state.image_data[i],
        project_id: parseInt(res.data.id),
        plan_name: this.state.planName
      }
      await this.makeRequest(imagePayload)
    }

    let updatedPlanPayload = {
      project_id: parseInt(res.data.id),
      uploaded_images: this.state.progressBarObject.counter,
      update_type: 'upload_status'
    }
    this.setState({
      ...this.state,
      progressBarObject: {
        ...this.state.progressBarObject,
        counter: 0,
        max: 0
      }
    })
    this.props.updatePlan(updatedPlanPayload).then((res) => {
      if (res.success) {
        this.setState({
          ...this.state,
          planName: ''
        })
        this.props.setPlanNameError('')
        runAttributeAlgo({ user_id: res.data.createdby, plan_id: res.data.id })
      }
      this.props.onCreatePlanCancel()
      // this.props.history.replace('/dashboard')
    })
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      planName: e.target.value
    })
  }

  validatePlanName = () => {
    if (this.state.planName !== '') return true
    return false
  }

  onClear = () => {
    this.setState({ planName: '' })
  }

  render() {
    let { planNameError } = this.props

    let { counter, max } = this.state.progressBarObject
    return (
      <Dialog open={this.props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            textAlign: 'center'
          }}>
          Create Plan
          <IconButton
            aria-label="close"
            onClick={this.props.onCreatePlanCancel}
            style={{ position: 'absolute', right: '0', top: '0' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ExcelFile element={<Button color="primary" variant="contained" style={{ position: "absolute", top: "6rem" , right: "5rem" }}>Download Input Template</Button>}>
          <ExcelSheet data={dataSet} name="Upload Plan">
            <ExcelColumn label="Image" value="Image"/>
            <ExcelColumn label="Sty/Clr ORIN" value="Sty/Clr ORIN"/>
          </ExcelSheet>
        </ExcelFile>
        <DialogContent>
          <TextField
            required
            autoComplete="off"
            maxWidth="lg"
            autoFocus
            margin="dense"
            id="planName"
            label="Plan Name"
            defaultValue="Hello World"
            value={this.state.planName}
            onChange={this.handleChange}
            error={this.state.planName === '' && planNameError !== ''}
            helperText={this.state.planName === '' ? planNameError : ' '}
          />
          <UploadContainerStyle>
            <UploadBoxStyle>
              <DragAndDrop
                uploadXLSXFile={this.uploadXLSXFile}
                validatePlanName={this.validatePlanName}
                filetype="Excelfile"
                imageIcon="excelupload.png"
                setPlanNameError={this.props.setPlanNameError}
                setExcelUploadError={this.props.setExcelUploadError}></DragAndDrop>
            </UploadBoxStyle>
          </UploadContainerStyle>
          {counter > 0 && (
            <ProgressBarContainerStyle>
              <ProgressBar counter={counter} max={max} text="Uploading" />
            </ProgressBarContainerStyle>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.onClear} style={{ right: '12px' }}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapActionsToProps = {
  setPlanNameError: planActions.setPlanNameError,
  setExcelUploadError: planActions.setExcelUploadError,
  createPlan: planActions.createPlan,
  updatePlan: planActions.updatePlan,
  uploadImageFromExcel: imageActions.uploadImageFromExcel
}
const mapStateToProps = (store) => {
  return {
    planNameError:
      store.planReducer.planNotification && store.planReducer.planNotification.planNameError,
    excelUploadError:
      store.planReducer.planNotification && store.planReducer.planNotification.excelUploadError
  }
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Plan))
