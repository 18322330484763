import { CardContent, CardHeader } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import commonActions from '../../actions'
import planActions from '../../actions/PlanActions'
import DataTable from '../../components/dataTable'
import DashboardColDefs from './columnDefs'
import CreatePlanDialog from './CreatePlan'
import Alert from '../../lib/Alert/alert'
import  FileDownloadRender  from './FileDownloadRender'

class Dashboard extends React.Component {
  state = {
    pageNumber: 1,
    isCreatePlanDialogOpen: false,
    isDeletePlan: false,
    frameworkComponents: {
      FileDownloadRender
    }
  }

  componentDidMount(){
    this.props.getPlanList({
      page: this.state.pageNumber,
      pageCount: 10000
    })
  }

  onCreatePlanClick = (e) => {
    // e.preventDefault();
    // this.props.setActiveNavIndex(1);
    // this.props.history.replace("/plan");
    this.setState({ isCreatePlanDialogOpen: true })
  }
  onCreatePlanCancel = () => {
    this.setState({ isCreatePlanDialogOpen: false })
  }

  onDeletePlanPopup = () => {
    let selectedRows = this.gridApi.getSelectedRows()
    selectedRows &&
      selectedRows.length &&
      this.setState({
        isDeletePlan: true
      })
  }

  onDeletePlanClick = () => {
    let selectedRows = this.gridApi.getSelectedRows()
    let ids = selectedRows.map((el) => el.id)
    this.props
      .deletePlan({
        projectIds: ids
      })
      .then(() => {
        this.setState({
          isDeletePlan: false
        })
        this.props.getPlanList({
          page: this.state.pageNumber,
          pageCount: 10000
        })
      })
  }

  // setPage = (pageNumber) => {
  //   this.setState({
  //     ...this.state,
  //     pageNumber: pageNumber
  //   })
  //   this.props.getPlanList({
  //     page: pageNumber,
  //     pageCount: 10
  //   })
  // }

  onCellClicked = (event) => {
    if (event.colDef.headerName === 'Plan name') {
      if (event && event.data && event.data.id && event.data.attribute_status === 'Success') {
        this.props.setActiveNavIndex(2)
        this.props.history.push(`/plan/${event.data.id}`)
      }
    }
  }

  getRowStyle = (params) => {
    if (params.data.attribute_status === 'Pending') {
      return { 'background-color': 'rgba(212,212,212,0.5)' }
    }
    if (params.data.attribute_status === 'Failed') {
      return { 'background-color': 'rgba(255,0,0,0.7)' }
    }
    return null
  }

  render() {
    let { planlist } = this.props
    return (
      <>
        <Card>
          <CardHeader title="Dashboard"></CardHeader>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button color="primary" variant="contained" onClick={this.onCreatePlanClick}>
                  Create Plan
                </Button>
              </div>
              <div style={{ marginLeft: '2rem' }}>
                <Button color="primary" variant="contained" onClick={this.onDeletePlanPopup}>
                  Delete Plan
                </Button>
              </div>
            </div>
            <div style={{ height: 'calc(100vh - 290px)', marginTop: '1rem' }}>
              <DataTable
                rowHeight="55"
                headerHeight="43"
                floatingFilter={true}
                sizeToFit={true}
                suppressColumnVirtualisation={true}
                columnDefs={DashboardColDefs.columnDefs}
                defaultColDefs={DashboardColDefs.defaultCoDef}
                rowData={planlist}
                onCellClicked={this.onCellClicked}
                getRowStyle={this.getRowStyle}
                passGridAPI={(api) => {
                  this.gridApi = api
                }}
                rowSelection="multiple"
                frameworkComponents={this.state.frameworkComponents}></DataTable>
            </div>
          </CardContent>
        </Card>
        <Alert
          open={this.state.isDeletePlan}
          title="Alert"
          isResetRequired={false}
          content="Are you sure you want to delete the plan ?"
          handleAccept={this.onDeletePlanClick}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isDeletePlan: false
            })
          }}
        />
        <CreatePlanDialog
          open={this.state.isCreatePlanDialogOpen}
          onCreatePlanCancel={this.onCreatePlanCancel}
        />
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    planlist: store.planReducer.planlist,
  }
}

const mapActionsToProps = {
  setActiveNavIndex: commonActions.setActiveNavIndex,
  getPlanList: planActions.getPlanList,
  deletePlan: planActions.deletePlan
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Dashboard))
