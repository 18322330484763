import styled from 'styled-components'

export const InputWrapperStyle = styled.div`
  padding: 0 10px;
`

export const TopLabelStyle = styled(InputWrapperStyle)`
  text-align: left;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  color: #a8a8a8
`
