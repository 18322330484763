import { CardContent, CardHeader } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import imageActions from '../../actions/imageActions'
import filterActions from '../../actions/filterActions'
import planActions from '../../actions/PlanActions'
import GetImagesSelectors from './GetImagesSelectors'
import ImageFilters from './ImageFilters'
import TabContainer from './TabContainer'

class PlanView extends React.Component {
  state = {
    planid: 1,
    pageNumber: 1
  }

  setImagePage = (pageNumber) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber
    },() => this.setPageNumber())
    this.props.getImageList({
      page: pageNumber,
      pageCount: this.props.noOfImages.value,
      projectId: this.state.planid,
      filterData: this.props.filterData
    })
  }
  componentWillMount() {
    let loc = this.props.location.pathname
    loc = loc.split('/')
    this.setState({
      ...this.state,
      planid: loc[loc.length - 1]
    },() => this.setPlanId())
  }

  setPageNumber = () => {
    this.props.setPageNumber(this.state.pageNumber)
  }

  setPlanId = () => {
    this.props.setPlanId(this.state.planid)
  }

  componentWillUnmount(){
    this.props.clearImageCount()
  }

  render() {
    return (
      <Card>
        <CardHeader title={`Plan: ${this.state.planid}`}></CardHeader>
        <CardContent>
          <GetImagesSelectors
            planid={this.state.planid}
            setImagePage={this.setImagePage}></GetImagesSelectors>
          <Card style={{ marginTop: '1rem' }}>
            <CardContent>
              <ImageFilters
                planid={this.state.planid}
                setImagePage={this.setImagePage}></ImageFilters>
              <TabContainer
                planid={this.state.planid}
                setImagePage={this.setImagePage}
                pageNumber={this.state.pageNumber}
                imagelist={this.props.imagelist}
                imageCount={this.props.imageCount}
                getImageList={this.props.getImageList}
                setImageList={this.props.setImageList}
                setAttributeType={this.props.setAttributeType}
                setAttributeValue={this.props.setAttributeValue}
                filterData={this.props.filterData}
              />
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    )
  }
}

const mapActionsToProps = {
  getImageList: imageActions.getImageList,
  getDepartmentsData: filterActions.getDepartmentsData,
  setImageList: imageActions.setImageList,
  setAttributeType: filterActions.setAttributeType,
  setAttributeValue: filterActions.setAttributeValue,
  clearImageCount: imageActions.clearImageCount,
  setPageNumber: planActions.setPageNumber,
  setPlanId: planActions.setPlanId,
}

const mapStateToProps = (store) => {
  return {
    imagelist: store.imageReducer.imagelist,
    imageCount: store.imageReducer.imageCount,
    noOfImages: store.filtersReducer.noOfImages,
    filterData: store.filtersReducer.filterData
  }
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(PlanView))
