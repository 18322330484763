import React from 'react'
import styled from 'styled-components'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'

const TopbarStyle = styled.div`
  height: 60px;
  box-shadow: 1px 1px 5px 1px #9696966b;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 800;
  top: 0;
`

const Topbar = ({ ...props }) => {
  const { title, username } = props
  return (
    <TopbarStyle>
      <div style={{ position: 'absolute', width: '100%', textAlign: 'center', zIndex: '-1' }}>
        <h4>{title}</h4>
      </div>
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'center',
          padding: '0 1rem',
          float: 'right',
          height: 'inherit'
        }}>
        <span>Hi! {username}</span>
        <ExitToAppSharpIcon
          style={{
            color: 'rgb(24, 153, 215)',
            paddingLeft: '20px',
            cursor: 'pointer'
          }}
        />
      </div>
    </TopbarStyle>
  )
}

export default Topbar
