import React, { Component } from 'react'
import styled from 'styled-components'

const FlexStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const WrapperStyle = styled(FlexStyle)`
  height: 100%;
  width: 100%;
`

const ImageStyle = styled(FlexStyle)`
  width: 100%;
  padding: auto;
  margin: auto;
`

const TextStyle = styled.div`
  width: 80%;
  text-align: center;
  font-size: 20px;
`
class DragAndDrop extends Component {
  state = {
    drag: false
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) this.setState({ drag: false })
  }

  hasExtension = (fileName, exts) => {
    return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName)
  }

  handleFiles = (files) => {
    //;[...files].forEach(this.uploadFile)
    if (this.props.filetype === 'Excelfile') {
      console.log('checking file', files[0])
      this.hasExtension(files[0].name, ['xlsx', 'ods'])
        ? this.props.uploadXLSXFile(files[0])
        : this.props.setExcelUploadError('File type not supported')
    }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    e.preventDefault()
    let planValidationFlag = this.props.validatePlanName()
    if (!planValidationFlag) {
      this.props.setPlanNameError('Please Provide the Plan Name and upload again')
    }

    if (planValidationFlag && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  componentDidMount() {
    this.dragCounter = 0
    let div = document.getElementById('drop-area')
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop, false)
  }

  componentWillUnmount() {
    let div = document.getElementById('drop-area')
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  onFileChangeHandler = (e) => {
    e.preventDefault()
    this.handleFiles(e.target.files)
  }

  render() {
    const { filetype, imageIcon } = this.props
    return (
      <WrapperStyle id="drop-area">
        <ImageStyle>
          <img
            src={require(`../../assets/${imageIcon}`)}
            style={{ width: '72px', height: '72px' }}
            alt=""
          />
        </ImageStyle>
        <TextStyle>
          Drop {filetype} here or <br />
          <div style={{ color: 'rgb(24, 153, 215)' }}>
            <label for="file_input_id" style={{ cursor: 'pointer' }}>
              Browse
            </label>
            <input
              type="file"
              id="file_input_id"
              style={{ display: 'none' }}
              onChange={this.onFileChangeHandler}
            />
          </div>
        </TextStyle>
      </WrapperStyle>
    )
  }
}

export default DragAndDrop
