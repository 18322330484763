const filtersReducerDefaultState = {
  userData: null,
  status: null,
  projectNamesList: null,
  hindsightProjectNamesList: null
}

export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.payload
      }
    case 'SET_LOGIN_STATUS':
      return {
        ...state,
        status: action.payload
      }
    case 'SET_RESETPASSWORD_STATUS':
      return {
        ...state,
        status: action.payload
      }
    case 'SET_FORGOTPASSWORD_STATUS':
      return {
        ...state,
        status: action.payload
      }
    default:
      return state
  }
}
