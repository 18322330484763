import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import imageActions from '../../../actions/imageActions'
import filterActions from '../../../actions/filterActions'
import DataTable from '../../../components/dataTable'
import Paginate from '../../../components/Paginate'
import { InputWrapperStyle, TopLabelStyle } from '../StyleComponents'
import Select from '../../../lib/SelectComponent'

import getColumnDefs from './columnDefs'
import { ImageCellRender, AttributeValueCellRenderer } from './ImageCellRender'
import CategoryValueCellRenderer from './ImageCellRender'

const ImagesCount = [
  { label: '20', value: '20' },
  { label: '40', value: '40' },
  { label: '60', value: '60' },
  { label: '80', value: '80' },
  { label: '100', value: '100' }
]

class AttributeView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      frameworkComponents: {
        ImageCellRender,
        CategoryValueCellRenderer,
        AttributeValueCellRenderer
      }
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.noOfImages.value !== this.props.noOfImages.value){
      if(this.props.category && this.props.category.value){
        this.props.getImageList({
          page: this.props.pageNumber,
          pageCount: this.props.noOfImages.value,
          projectId: this.props.planid,
          filterData: this.props.filterData
        })
      }
    }
  }
  onSubmitAttributeValues = (e) => {
    e.preventDefault()
    const filterData = {
      department: this.props.department.value,
      class_desc: this.props.classValue.value,
      category: this.props.category.value,
      vendor_desc: this.props.vendor.value
    }
    this.props.updateImageAttrMapping &&
      Object.keys(this.props.updateImageAttrMapping).length &&
      this.props
        .changeAttributeValues({ imageAttributeObj: this.props.updateImageAttrMapping,updateType: 'attribute' })
        .then((resp) => {
          if (resp.success) {
            toast.success(resp.data.message)
            this.props.clearImageAttrMapping()
            this.props.getAttributes({
              projectId: this.props.planid,
              filterData
            })
            this.props.getSubCategoriesData({
              projectId: this.props.planid,
              filterData
            })
            this.props.getImageList({
              page: this.props.pageNumber,
              pageCount: this.props.noOfImages.value,
              projectId: this.props.planid,
              filterData: this.props.filterData
            })
          }
        })
  }

  onChangeImageCount = (val) => {
    if (val) {
      this.props.setImageCount(val)
    }
  }

  render() {
    const { columnDefs, defaultCoDef } = getColumnDefs(this.props.attributeTypeList)
    const attrTableData =
      this.props.imagelist &&
      this.props.imagelist.map((image) => {
        let attrVal = image.attribute && Object.keys(image.attribute).map((key) => ({
          [key]: image.attribute[key].attribute_value
        }))
        let objAttrVal = attrVal && Object.assign({}, ...attrVal)
        const attrData = { url: image.url, id: image.id }
        const rmsData = { rms: image.rms_values }
        return {
          ...attrData,
          ...rmsData,
          ...objAttrVal,
          categoryOptions: this.props.categoryOptionOverallLevel,
          attributeOptions: this.props.attributeOptions,
          attributeVal: image.attribute
        }
      })
    let { imageCount, pageNumber } = this.props
    let pageCount =
      imageCount % this.props.noOfImages.value === 0
        ? Number(Math.floor(imageCount / this.props.noOfImages.value))
        : Number(Math.floor(imageCount / this.props.noOfImages.value)) + 1
    return (
      <>
        <div align="justify" style={{ height: 'calc(100vh - 428px)', marginTop: '8px' }}>
          <DataTable
            // sizeToFit={true}
            columnDefs={columnDefs}
            defaultColDefs={defaultCoDef}
            rowData={attrTableData}
            rowHeight={200}
            frameworkComponents={this.state.frameworkComponents}></DataTable>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Button
            disabled={!imageCount}
            style={{ float: 'right' }}
            color="primary"
            variant="contained"
            onClick={this.onSubmitAttributeValues}>
            Update
          </Button>
          <div style={{ display: 'inline-block', marginBottom: '7rem' }}>
            <InputWrapperStyle>
              <TopLabelStyle>No of Images/Page</TopLabelStyle>
              <Select
                closeMenuOnSelect={true}
                isClearable
                value={this.props.noOfImages}
                placeholder={'Select'}
                options={ImagesCount || []}
                onChange={(val) => this.onChangeImageCount(val)}
              />
            </InputWrapperStyle>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Paginate
              pageNumber={pageNumber}
              pageCount={pageCount}
              setPage={this.props.setImagePage}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    department: store.filtersReducer.department,
    classValue: store.filtersReducer.classValue,
    category: store.filtersReducer.category,
    vendor: store.filtersReducer.vendor,
    noOfImages: store.filtersReducer.noOfImages,
    imagelist: store.imageReducer.imagelist,
    filterData: store.filtersReducer.filterData,
    updateImageAttrMapping: store.imageReducer.updateImageAttrMapping,
    attributeTypeList: store.filtersReducer.attributeTypeList,
    attributeOptions: store.filtersReducer.attributeOptions,
    categoryOptionOverallLevel: store.filtersReducer.categoryOptionOverallLevel
  }
}
const mapActionsToProps = {
  getImageList: imageActions.getImageList,
  setImageCount: filterActions.setImageCount,
  changeAttributeValues: imageActions.changeAttributeValues,
  clearImageAttrMapping: imageActions.clearImageAttrMapping,
  getAttributes: filterActions.getAttributes,
  getSubCategoriesData: filterActions.getSubCategoriesData,
}

export default connect(mapStateToProps, mapActionsToProps)(AttributeView)
