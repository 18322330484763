import { dateFormatter } from '../../helpers/formatters'
export default {
  defaultColDef: {
    animateRows: true,
    // sortable: true,
    resizable: true,
    // unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: 'no-border',
  columnDefs: [
    {
      headerName: 'Plan name',
      field: 'name',
      filter: 'agTextColumnFilter',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px' }
        } else {
          return { cursor: 'pointer', color: 'rgb(24, 153, 215)', lineHeight: '60px' }
        }
      }
    },
    {
      headerName: 'No.of images',
      field: 'num_of_images',
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px' }
        } else {
          return { lineHeight: '60px' }
        }
      }
    },
    {
      headerName: 'Images Uploaded',
      field: 'uploaded_images',
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px' }
        } else {
          return { lineHeight: '60px' }
        }
      }
    },
    // {
    //   headerName: 'User',
    //   field: 'username',
    //   cellStyle: function(params) {
    //     if (params.data.attribute_status === 'Failed') {
    //       return { color: 'white', lineHeight: '60px' }
    //     } else {
    //       return { lineHeight: '60px' }
    //     }
    //   }
    // },
    {
      headerName: 'Date',
      filter: 'agTextColumnFilter',
      field: 'created_at',
      valueFormatter: dateFormatter,
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px', wordSpacing: '15px' }
        } else {
          return { lineHeight: '60px', wordSpacing: '15px' }
        }
      }
    },
    {
      headerName: 'Upload Status',
      filter: 'agTextColumnFilter',
      field: 'upload_status',
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px' }
        } else {
          return { lineHeight: '60px' }
        }
      },
      valueGetter: (params) => {
        if (params.data.upload_status) return 'Uploaded'
      }
    },
    {
      headerName: 'Attribute Status',
      filter: 'agTextColumnFilter',
      field: 'attribute_status',
      cellStyle: function(params) {
        if (params.data.attribute_status === 'Failed') {
          return { color: 'white', lineHeight: '60px' }
        } else {
          return { lineHeight: '60px' }
        }
      },
      valueGetter: (params) => {
        return params.data.attribute_status
      }
    },
    {
      headerName: 'File Download',
      cellRenderer: 'FileDownloadRender',
      cellStyle: function(params) {
        if (
          params.data.attribute_status !== 'Success' ||
          (params.data.num_of_images === params.data.uploaded_images)
        ) {
          return { display: 'none' }
        }
      }
    }
  ]
}
