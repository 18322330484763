import devConfigureStore from './configureStore.dev'
import prodConfigureStore from './configureStore.prod'

let store

if (process.env.NODE_ENV === 'PROD_DEV') {
  store = prodConfigureStore()
} else {
  store = devConfigureStore()
}

export default store
