import customToast from '../lib/customToast'
import api from '../helpers'
const planActions = {
  createPlan: (payload) => {
    return (dispatch) => {
      return api({
        url: '/project/createProject',
        method: 'POST',
        data: payload
      })
        .then((resp) => {
          dispatch({
            type: 'CREATE_PLAN',
            payload: resp.data
          })
          customToast.info('Plan created')
          return resp
        })
        .catch((err) => {
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          customToast.error(err.response.data.message)
          return err
        })
    }
  },
  updatePlan: (payload) => {
    return (dispatch) => {
      return api({
        url: '/project/updateProject',
        method: 'POST',
        data: payload
      })
        .then((resp) => {
          dispatch({
            type: 'UPDATE_PLAN',
            payload: resp.data
          })

          return resp
        })
        .catch((err) => {
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          console.log('plan updation error', err)
          return err
        })
    }
  },
  deletePlan: (payload) => {
    return (dispatch) => {
      return api({
        url: '/project/deleteProjects',
        method: 'POST',
        data: payload
      })
        .then((resp) => {
          dispatch({
            type: 'DELETE_PLAN',
            payload: resp
          })

          return resp
        })
        .catch((err) => {
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          console.log('plan deletion error', err)
          return err
        })
    }
  },
  getDownloadfailedImageData: payload => {
    return dispatch => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })
      return api({
        url: "/image/downloadfailedImageData",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch((err) => {
          console.log(err)
        })
    };
  },
  getPlanList: (params) => {
    return (dispatch) => {
      dispatch({
        type: 'LOADER_STATE',
        payload: {
          status: true
        }
      })

      return api({
        url: '/project/getProjectList',
        method: 'GET',
        params: params
      })
        .then((resp) => {
          dispatch({
            type: 'GET_PLAN_LIST',
            payload: resp.data.plans
          })
          if (resp.data.count)
            dispatch({
              type: 'SET_PLAN_COUNT',
              payload: resp.data.count
            })
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: false
            }
          })
          return resp
        })
        .catch((err) => {
          //toast.error(err.error, { autoClose: 15000, hideProgressBar: true })
          dispatch({
            type: 'LOADER_STATE',
            payload: {
              status: false
            }
          })
          console.log('plan updation error', err)
          return err
        })
    }
  },
  setPlanNameError: (msg) => {
    return (dispatch) => {
      dispatch({
        type: 'PLAN_NAME_ERROR',
        payload: msg
      })
    }
  },
  setPageNumber: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_PAGE_NUMBER',
        payload: data
      })
    }
  },
  setPlanId: (data) => {
    return (dispatch) => {
      dispatch({
        type: 'SET_PLAN_ID',
        payload: data
      })
    }
  },
  setExcelUploadError: (msg) => {
    customToast.error(msg)
    return (dispatch) => {
      dispatch({
        type: 'EXCEL_UPLOAD_ERROR',
        payload: msg
      })
    }
  }
}

export default planActions
