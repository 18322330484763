import AppLayout from '../layouts/AppLayout'

const indexRoutes = [
  {
    path: '/',
    component: AppLayout
  }
]

export default indexRoutes
