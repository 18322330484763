import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Select from '../../lib/SelectComponent'
import { Row, Column } from '../../lib/Grid'
import filterActions from '../../actions/filterActions'
import imageAction from '../../actions/imageActions'
import commonActions from '../../actions/index'
import { InputWrapperStyle, TopLabelStyle } from './StyleComponents'

class GetImagesSelectors extends React.Component {
  state = {
    downloadAttribute: false
  }
  componentDidMount() {
    this.props.getClassesData({
      projectId: this.props.planid
    })
    this.props.getCategoriesData({
      projectId: this.props.planid
    })
    this.props.getDepartmentsData({
      projectId: this.props.planid
    })
    this.props.getVendorsData({
      projectId: this.props.planid
    })
    //this.props.getDepartmentsData({ projectId: this.props.planid })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.attributeOptionsProjectLevel !== this.props.attributeOptionsProjectLevel) {
      this.props.setAttributeType(
        this.props.attributeType
          ? this.props.attributeType
          : { label: 'Sub Category', value: 'Sub Category' }
      )
    }
  }
  onCategoryFilter = (val) => {
    if (val) {
      this.props.setCategory(val)
    } else {
      this.props.setCategory({ label: '', value: '' })
    }
    this.props.setSubCategory({ label: '', value: '' })
    this.props.setAttributeType({ label: 'Sub Category', value: 'Sub Category' })
    this.props.setAttributeValue({ label: '', value: '' })
  }

  onClassSelect = (val) => {
    this.onResetClick()
    if (val) {
      this.props.setClasses(val)
      // this.props.getDepartmentsData({
      //   projectId: this.props.planid,
      //   filterData: { class_desc: String(val.value) }
      // })
    }
  }
  onDepartmentSelect = (val) => {
    this.props.setDepartment({ label: '', value: '' })
    // this.props.setVendor({ label: '', value: '' })
    if (val) {
      this.props.setDepartment(val)
      // this.props.getVendorsData({
      //   projectId: this.props.planid,
      //   filterData: {
      //     department: val && val.map((ele) => ele.value),
      //     class_desc: this.props.classValue.value
      //   }
      // })
    }
  }

  onVendorSelect = (val) => {
    this.props.setVendor({ label: '', value: '' })
    if (val) {
      this.props.setVendor(val)
    }
  }

  onChangeImageCount = (val) => {
    if (val) {
      this.props.setImageCount(val)
    }
  }

  onFilterClick = () => {
    this.props.setAttributeType({ label: 'Sub Category', value: 'Sub Category' })
    this.props.setIsGridView('Grid View')
    this.props.setAttributeValue({ label: '', value: '' })
    this.props.setStatusValue({ label: 'Non Finalized', value: 'Non Finalized' })
    this.props.setImageList([])
    const filterData = {
      department: this.props.department.value,
      class_desc: this.props.classValue.value,
      category: this.props.category.value,
      vendor_desc: this.props.vendor.value
    }
    this.props.getSubCategoriesData({
      projectId: this.props.planid,
      filterData
    })
    this.props
      .getAttributes({
        projectId: this.props.planid,
        filterData
      })
      .then((resp) => this.setState({ downloadAttribute: true }))
    const ref = this
    setTimeout(function() {
      ref.props.setImagePage(1)
    }, 0)
  }

  onResetClick = () => {
    this.props.setImageCount({ label: '100', value: '100' })
    this.props.setStatusValue({ label: '', value: '' })
    this.props.setImageList([])
    this.props.setDepartment('')
    this.props.setClasses({ label: '', value: '' })
    this.props.setVendor({ label: '', value: '' })
    this.props.setCategory({ label: '', value: '' })
    this.props.setSubCategory({ label: '', value: '' })
    this.props.setAttributeType({ label: '', value: '' })
    this.props.setAttributeValue({ label: '', value: '' })
    this.props.setImageList([])
    this.setState({ downloadAttribute: false })
  }

  onDownloadAttribute = () => {
    const filterData = {
      department: this.props.department.length ? this.props.department.map((el) => el.value) : [],
      class_desc: this.props.classValue.value,
      category: this.props.category.value,
      vendor_desc: this.props.vendor.value,
      sub_category: '',
      attributeType: '',
      attributeValue: '',
      confidenceLevel: '',
      status: ''
    }
    this.props
      .getDownloadAttributes({
        projectId: this.props.planid,
        filterData
      })
      .then((resp) => {
        let a2 = document.createElement('A')
        a2.href = resp.data.url
        document.body.appendChild(a2)
        a2.click()
        document.body.removeChild(a2)
      })
  }

  componentWillUnmount() {
    this.props.setImageCount({ label: '100', value: '100' })
    this.props.setDepartment('')
    this.props.setClasses({ label: '', value: '' })
    this.props.setVendor({ label: '', value: '' })
    this.props.setCategory({ label: '', value: '' })
    this.props.setSubCategory({ label: '', value: '' })
    this.props.setAttributeType({ label: '', value: '' })
    this.props.setAttributeValue({ label: '', value: '' })
    this.props.setImageList([])
    this.props.clearCategoriesData()
  }

  render() {
    return (
      <Row>
        <Column xs="6" sm="4" md="2">
          <InputWrapperStyle>
            <TopLabelStyle>Class</TopLabelStyle>
            <Select
              closeMenuOnSelect={true}
              isClearable
              value={this.props.classValue.value === '' ? null : this.props.classValue}
              placeholder={'Select'}
              options={(this.props && this.props.classesList) || []}
              onChange={(val) => this.onClassSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="4" md="2">
          <InputWrapperStyle>
            <TopLabelStyle>Departments</TopLabelStyle>
            <Select
              // isDisabled={!this.props.classValue.value}
              closeMenuOnSelect={false}
              allowSelectAll={true}
              isMulti={true}
              isClearable
              value={
                this.props.department.value === '' && this.props.classValue.value
                  ? null
                  : this.props.department
              }
              placeholder={'Select'}
              options={this.props.departmentsList || []}
              onChange={(val) => this.onDepartmentSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="4" md="2">
          <InputWrapperStyle>
            <TopLabelStyle>Vendor</TopLabelStyle>
            <Select
              // isDisabled={!this.props.department.length}
              closeMenuOnSelect={true}
              isClearable
              value={this.props.vendor.value === '' ? null : this.props.vendor}
              placeholder={'Select'}
              options={this.props.vendorsList || []}
              onChange={(val) => this.onVendorSelect(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="3.5" md="2">
          <InputWrapperStyle>
            <TopLabelStyle>Category*</TopLabelStyle>
            <Select
              closeMenuOnSelect={true}
              isDisabled={!this.props.categoriesList.length}
              isClearable
              value={
                this.props.category.value === '' && this.props.categoriesList.length
                  ? null
                  : this.props.category
              }
              placeholder={'Select'}
              options={this.props.categoriesList || []}
              onChange={(val) => this.onCategoryFilter(val)}
            />
          </InputWrapperStyle>
        </Column>
        <Column xs="6" sm="4" md="5">
          <Button
            disabled={!this.props.category.value}
            style={{ margin: '0 0.5rem' }}
            color="primary"
            variant="contained"
            onClick={this.onFilterClick}>
            Get Images
          </Button>
          <Button
            style={{ margin: '0 0.5rem' }}
            color="primary"
            variant="outlined"
            onClick={this.onResetClick}>
            Reset
          </Button>
          <Button
            disabled={!this.state.downloadAttribute}
            style={{ margin: '0 0.5rem' }}
            color="primary"
            variant="contained"
            onClick={this.onDownloadAttribute}>
            Download Attributes
          </Button>
        </Column>
      </Row>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    department: store.filtersReducer.department,
    classValue: store.filtersReducer.classValue,
    category: store.filtersReducer.category,
    vendor: store.filtersReducer.vendor,
    attributeType: store.filtersReducer.attributeType,
    attributeValue: store.filtersReducer.attributeValue,
    departmentsList: store.filtersReducer.departmentsList,
    classesList: store.filtersReducer.classesList,
    vendorsList: store.filtersReducer.vendorsList,
    categoriesList: store.filtersReducer.categoriesList,
    attributeTypeList: store.filtersReducer.attributeTypeList,
    attributeValueList: store.filtersReducer.attributeValueList,
    attributeOptionsProjectLevel: store.filtersReducer.attributeOptionsProjectLevel
  }
}

const mapDispatchToProps = {
  setImageCount: filterActions.setImageCount,
  setIsGridView: commonActions.setIsGridView,
  setStatusValue: filterActions.setStatusValue,
  getDepartmentsData: filterActions.getDepartmentsData,
  getClassesData: filterActions.getClassesData,
  getVendorsData: filterActions.getVendorsData,
  getCategoriesData: filterActions.getCategoriesData,
  getSubCategoriesData: filterActions.getSubCategoriesData,
  setDepartment: filterActions.setDepartment,
  setImageList: imageAction.setImageList,
  setClasses: filterActions.setClasses,
  setVendor: filterActions.setVendor,
  setCategory: filterActions.setCategory,
  setSubCategory: filterActions.setSubCategory,
  setAttributeType: filterActions.setAttributeType,
  setAttributeValue: filterActions.setAttributeValue,
  getAttributes: filterActions.getAttributes,
  clearCategoriesData: filterActions.clearCategoriesData,
  getDownloadAttributes: filterActions.getDownloadAttributes
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GetImagesSelectors))
