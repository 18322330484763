// @material-ui/icons
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'
import PlanViewLayout from '../layouts/PlanViewLayout'
// core components/views
import Dashboard from '../views/Dashboard'

const dashboardRoutes = [
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    icon: PhotoAlbumIcon,
    component: Dashboard
  },
  {
    path: '/plan/:id',
    sidebarName: 'Plan',
    navbarName: 'Plan',
    imageIconInActive: 'PlanListFilled.png',
    imageIconActive: 'PlanListOutline.png',
    component: PlanViewLayout
  },

  { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
]

export default dashboardRoutes
