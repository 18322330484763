import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { css } from 'glamor'
import './index.css'
const ToastStyle = styled.div`
  font-weight: bold;
`

const ErrorToastStyle = styled(ToastStyle)`
  color: white;
  line-height: 30px;
  position: relative;
`

const WarningToastStyle = styled(ToastStyle)`
  color: black;
  line-height: 30px;
  position: relative;
`

const ErrorToastComponent = ({ message }) => (
  <ErrorToastStyle>
    <div style={{ position: 'absolute' }}>
      <span style={{ verticalAlign: 'middle' }}>
        <i className="fa fa-exclamation-circle fa-2x" aria-hidden="true" />
      </span>
    </div>

    <div style={{ marginLeft: '40px' }}>{message}</div>
  </ErrorToastStyle>
)

const WarningToastComponent = ({ message }) => (
  <WarningToastStyle>
    <div style={{ position: 'absolute' }}>
      <span style={{ verticalAlign: 'middle' }}>
        <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true" />
      </span>
    </div>

    <div style={{ marginLeft: '40px' }}>{message}</div>
  </WarningToastStyle>
)

const InfoToastComponent = ({ message }) => (
  <ErrorToastStyle>
    <div style={{ position: 'absolute' }}>
      <span style={{ verticalAlign: 'middle' }}>
        <i className="fa fa-info fa-2x" aria-hidden="true" />
      </span>
    </div>

    <div style={{ marginLeft: '30px', fontSize: '20px' }}>{message}</div>
  </ErrorToastStyle>
)

export default {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success-container toast-success-container-after',
      progressClassName: css({
        background: '#34A853'
      })
    })
  },
  error(msg, options = {}) {
    return toast(<ErrorToastComponent message={msg} />, {
      ...options,
      className: 'toast-error-container',
      progressClassName: css({
        background: '#34A853'
      })
    })
  },
  warning(msg, options = {}) {
    return toast(<WarningToastComponent message={msg} />, {
      ...options,
      className: 'toast-warning-container',
      progressClassName: css({
        background: '#34A853'
      })
    })
  },
  info(msg, options = {}) {
    return toast(<InfoToastComponent message={msg} />, {
      ...options,
      className: 'toast-info-container',
      progressClassName: css({
        background: '#34A853'
      })
    })
  }
}
