import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button onClick={props.handleClose} color="primary">
          {props.cancelButtonText}
        </Button>
        <div>
          {props.isResetRequired && (
            <Button onClick={props.handleReset} color="primary">
              Reset
            </Button>
          )}
          <Button onClick={props.handleAccept} color="primary" autoFocus>
            {props.actionButtonText}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
