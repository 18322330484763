import React from 'react'
import PlanView from '../views/PlanView'

class PlanViewLayout extends React.Component {
  state = {}

  render() {
    return <PlanView />
  }
}

export default PlanViewLayout
