import React, { Component } from 'react'
import { omitBy,cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { pickBy, startsWith } from 'lodash'
import Select from 'react-select'
import store from '../../../store'
import Alert from '../../../lib/Alert/alert'
import imageAction from '../../../actions/imageActions'
import filterActions from '../../../actions/filterActions'

export function ImageCellRender(props) {
  return <img src={props.value} alt={props.value} height="200px" width="175px" />
}

export class CategoryValueCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: { label: this.props.category.value, value: this.props.category.value },
      selectedVal: {},
      openPopup: false
    }
  }

  componentDidMount() {
    let data = this.props.data && this.props.data.categoryOptions
    let catOption = Object.keys(data).map((el) => ({ label: el, key: el }))
    this.setState({
      catOption
    })
  }

  onOpenPopup = (val) => {
    this.setState(
      {
        selectedVal: val
      },
      () => this.openPop()
    )
  }

  openPop = () => {
    this.setState({
      openPopup: true
    })
  }

  onChangeCategory = () => {
    this.setState(
      {
        selectedValue: this.state.selectedVal,
        openPopup: false
      },
      this.callChangeCategory()
    )
  }

  callChangeCategory = () => {
    let data = this.props.data && this.props.data.categoryOptions
    let selectedCat = this.state.selectedVal.key
    let id = this.props.data.id
    let result = pickBy(data, function(value, key) {
      return startsWith(key, selectedCat)
    })

    let reqObj = {
      [id]: {
        id: id,
        ...result
      }
    }

    this.props.changeOverAllCategoriesValues(reqObj).then((resp) => {
      if (resp.success) {
        toast.success('Successfully Changed Category')
        this.props.getCategoriesData({
          projectId: this.props.planId
        })
          this.props.getImageList({
            page: this.props.pageNumber,
            pageCount: this.props.noOfImages.value,
            projectId: this.props.planId,
            filterData: this.props.filterData
          })
      }
    })
  }

  render() {
    return (
      <>
        <Select
          value={this.state.selectedValue || []}
          options={this.state.catOption || []}
          onChange={this.onOpenPopup}
        />
        <Alert
          open={this.state.openPopup}
          title="Alert"
          isResetRequired={false}
          content={`Are you sure you want to change the category from ${this.props.category.value} to ${this.state.selectedVal.key}`}
          handleAccept={this.onChangeCategory}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              openPopup: false
            })
          }}
        />
      </>
    )
  }
}

export class AttributeValueCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.updateImageAttrMapping = store.getState().imageReducer.updateImageAttrMapping
    this.state = {
      selectedValue: { label: this.props.value, value: this.props.value },
      updImg: {
        id: this.props.data.id,
        attr_data: {
          [this.props.column.colDef.field]: {
            attr_value: '',
            last_updated_value: ''
          }
        }
      }
    }
  }

  updateImage = (values) => {
   if(values.value !== this.props.value){
    this.setState({ selectedValue: values })
    if (!this.updateImageAttrMapping.hasOwnProperty([this.props.data.id])) {
      this.updateImageAttrMapping[this.props.data.id] = {}
    }
    let initial = omitBy(
      this.props.data.attributeVal,
      (value, key) => !key.startsWith(this.props.column.colDef.field)
    )
    let iniValObj = Object.values(initial)
    let attrObj = cloneDeep(iniValObj)
    attrObj[0].attribute_value = values.value
    this.updateImageAttrMapping[this.props.data.id] = {
      id: this.props.data.id,
      attr_data: {
        [this.props.column.colDef.field]: {
          ...attrObj[0],
          last_updated_value: this.props.value || ''
        },
        ...this.updateImageAttrMapping[this.props.data.id].attr_data
      }
    }
  }
  }

  render() {
    return (
      <div>
        <div
          style={{
            visibility: this.state.selectedValue.value !== this.props.value ? 'visible' : 'hidden',
            textAlign: 'left'
          }}>
          <i>{this.props.value}</i>
        </div>
        <Select
          value={this.state.selectedValue}
          options={this.props.data.attributeOptions[this.props.column.colDef.field]}
          onChange={(values) => this.updateImage(values)}
        />
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    noOfImages: store.filtersReducer.noOfImages,
    filterData: store.filtersReducer.filterData,
    planId: store.planReducer.planId,
    pageNumber: store.planReducer.pageNumber,
    category: store.filtersReducer.category,
    attributeOptions: store.filtersReducer.attributeOptions,
  }
}

const mapActionsToProps = {
  changeOverAllCategoriesValues: imageAction.changeOverAllCategoriesValues,
  getImageList: imageAction.getImageList,
  getCategoriesData: filterActions.getCategoriesData,
}

export default connect(mapStateToProps, mapActionsToProps)(CategoryValueCellRenderer)
