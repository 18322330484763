const imageReducerDefaultState = {
  imagelist: [],
  imageCount: 0,
  checkedIds: {},
  updateImageAttrMapping: {},
  selectedAttrValues: {},
  selectedFinalizeAttr: {},
  clearCheckBoxVal: false
}

export default (state = imageReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_IMAGE_LIST':
      return {
        ...state,
        imagelist: action.payload
      }
    case 'SET_IMAGE_COUNT':
      return {
        ...state,
        imageCount: action.payload
      }
    case 'CLEAR_IMAGE_DATA':
      return {
        ...imageReducerDefaultState
      }
    case 'CLEAR_IMAGE_ATTR_MAPPING':
      return {
        ...state,
        updateImageAttrMapping: {}
      }
    case 'SET_CHECKED':
      return {
        ...state,
        checkedIds: { ...state.checkedIds, [action.payload.imageId]: action.payload.flag }
      }
    case 'SET_ATTRIBUTE_VAL':
      return {
        ...state,
        selectedAttrValues: { ...state.selectedAttrValues, ...action.payload }
      }
    case 'SET_FINALIZE_ATTRIBUTE':
      return {
        ...state,
        selectedFinalizeAttr: { ...state.selectedFinalizeAttr, ...action.payload }
      }
    case 'CLEAR_FINALIZE_ATTRIBUTE':
      return {
        ...state,
        selectedFinalizeAttr: {}
      }
    case 'CLEAR_ATTRIBUTE_VAL':
      return {
        ...state,
        selectedAttrValues: {}
      }
    case 'CLEAR_IMAGE_COUNT':
      return {
        ...state,
        imageCount: 0
      }
    case 'CLEAR_CHECKED':
      return {
        ...state,
        checkedIds: {}
      }
    case 'CLEAR_CHECK_BOX':
      return {
        ...state,
        clearCheckBoxVal: !state.clearCheckBoxVal
      }
    default:
      return state
  }
}
