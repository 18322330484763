import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { AgGridReact } from 'ag-grid-react'
import React, { Component } from 'react'

class DataTable extends Component {
  constructor(props) {
    super(props)
    this.gridApi = null
    this.state = {
      currentCount: null
    }
  }
  componentDidUpdate() {
    if (this.gridApi) {
      this.gridApi.refreshCells()
      this.gridApi.refreshClientSideRowModel('aggregate')
    }
  }
  onGridReady = (params) => {
    this.gridColumnApi = params.columnApi
    this.gridApi = params.api
    let tempCount = this.gridApi.getDisplayedRowCount()
    this.setState({
      currentCount: tempCount
    })
    this.props.passGridAPI && this.props.passGridAPI(params.api)
    this.props.excelRefs && this.props.excelRefs(this.ExportExcel)
  }

  handleFirstDataRendered = (event) => {
    this.props.sizeToFit && event.api.sizeColumnsToFit()
    // : event.columnApi.autoSizeAllColumns();
  }
  gridSizeChanged = (event) => {
    this.props.sizeToFit && event.api.sizeColumnsToFit()
    // : event.columnApi.autoSizeAllColumns();
  }

  ExportExcel = () => {
    var params = {
      fileName: 'LineArchitecture',
      sheetName: 'sheet1'
    }
    this.gridApi.exportDataAsExcel(params)
  }

  render() {
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: '100%'
        }}>
        <AgGridReact
          suppressMenuHide={false}
          singleClickEdit={true}
          animateRows={true}
          onGridReady={this.onGridReady}
          suppressAggFuncInHeader={true}
          groupDefaultExpanded={this.props.defaultExpand ? -1 : null}
          frameworkComponents={this.frameworkComponents}
          rowSelection="multiple"
          groupSelectsChildren={true}
          onFirstDataRendered={this.handleFirstDataRendered}
          onGridSizeChanged={this.gridSizeChanged}
          {...this.props}
        />
      </div>
    )
  }
}

export default DataTable
